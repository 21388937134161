<template>
    <ListaFluxo />
</template>

<script>
import ListaFluxo from '@/components/Fluxo/ListaFluxo'
export default {
  components: {ListaFluxo},
  data(){
    return{}
  }
}
</script>

<style>

</style>